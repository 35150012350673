@font-face {
  font-family: 'GT-Walsheim-Pro-Bold';
  src: local('GT-Walsheim-Pro-Bold'),
    url(../assets/fonts/GT-Walsheim-Pro-Bold.ttf) format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: 'GT-Walsheim-Pro-Medium';
  src: local('GT-Walsheim-Pro-Medium'),
    url(../assets/fonts/GT-Walsheim-Pro-Medium.ttf) format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: 'GT-Walsheim-Pro-Regular';
  src: local('GT-Walsheim-Pro-Regular'),
    url(../assets/fonts/GT-Walsheim-Pro-Regular.ttf) format('truetype');
  font-display: fallback;
}
